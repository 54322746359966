import React from "react";

function Footer() {
    return (
        <footer className="text-white text-center text-xs p-3">
            Adam Florence Resume<br></br>
            &copy; Copyright 2023
        </footer>
    )
}

export default Footer